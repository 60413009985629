<script>
    import Button from "~/components/Button.svelte";

    import facebookIcon from "@/images/icons/facebook.svg?raw";
    import instagramIcon from "@/images/icons/instagram.svg?raw";
    import chromeIcon from "@/images/icons/chrome.svg?raw";
    import edgeIcon from "@/images/icons/edge.svg?raw";
    import firefoxIcon from "@/images/icons/firefox.svg?raw";
    import ieIcon from "@/images/icons/internet-explorer.svg?raw";
    import operaIcon from "@/images/icons/opera.svg?raw";
    import safariIcon from "@/images/icons/safari.svg?raw";
    import globeIcon from "@/images/icons/globe.svg?raw";

    import localizer from "~/common/i18n.js";
    import Form from "~/components/Form.svelte";
    import MessageBox from "~/components/MessageBox.svelte";
    import LinkButton from "~/components/LinkButton.svelte";
    import InlineSVG from "~/components/InlineSVG.svelte";

    export let sessions = [];
    export let embeddedMode = false;
    export let maxSessions;
    export let requestedUrl;

    const icons = {
        facebook: facebookIcon,
        instagram: instagramIcon,
        chrome: chromeIcon,
        edge: edgeIcon,
        firefox: firefoxIcon,
        ie: ieIcon,
        opera: operaIcon,
        safari: safariIcon,
        globe: globeIcon,
    }

    const CSRFTOKEN = document.querySelector('meta[name="csrf-token"]').content;

    let notification;

    function displayNotification(n) {
        notification = n;
    }

    const logoutSession = async session => {
        const config = {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'X-CSRF-Token': CSRFTOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                hash: session.hash,
            }),
        };

        const response = await fetch(`/aid/innlogginger/slett`, config)
            .then(res => {
                if (!res.ok) {
                    displayNotification(t('error_response_not_ok'));
                    throw Error(res.statusText);
                }
                return res;
            })
            .catch(error => console.warn(error));

            response
                .json()
                .then(parsedResponse => {
                    if (parsedResponse.require_login || parsedResponse.require_sudomode) {
                        window.location.reload();
                    } else if (parsedResponse.session_deleted) {
                        sessions = sessions.filter(s => s.hash != session.hash);
                    } else {
                        displayNotification(t('error_response_unknown'));
                    }
                })
                .catch(err => {
                    displayNotification(t('error_response_invalid_json'));
                    console.warn(err);
                });
    }

    const t = localizer({
        nb: {
            title: 'Du er for tiden logget inn på aID følgende steder:',
            sessions: 'innlogginger',
            session: 'innlogging',
            current_session: 'Denne innloggingen',
            last_used: 'Sist brukt:',
            visited_publications: 'Besøkte publikasjoner',
            show_more_publications: 'Vis {{num}} flere',
            logout_device: 'Fjern denne innloggingen',
            logout_all: 'Fjern alle innlogginger',
            back: 'Tilbake til profil',
            continue: 'Trykk her for å fortsette',
            too_long: 'Du må fjerne {{num}} innlogginger for å fortsette.',
            max_length_success: 'Godt jobba!',
            error_response_not_ok: 'Nettverksfeil',
            error_response_invalid_json: 'Noe feilet ved sletting av denne innloggingen',
            error_response_unknown: 'Noe feilet ved sletting av denne innloggingen',
        },
        nn: {
            title: 'Du er for tida logga inn på aID følgjande stader:',
            sessions: 'innloggingar',
            session: 'innlogging',
            current_session: 'Denne innlogginga',
            last_used: 'Sist brukt:',
            visited_publications: 'Besøkte publikasjonar',
            show_more_publications: 'Vis {{num}} fleire',
            logout_device: 'Fjern denne innloggina',
            logout_all: 'Fjern alle innloggingar',
            back: 'Tilbake til profil',
            continue: 'Trykk her for å fortsette',
            too_long: 'Du må fjerne {{num}} innloggingar for å fortsette.',
            max_length_success: 'Godt jobba!',
            error_response_not_ok: 'Nettverksfeil',
            error_response_invalid_json: 'Noko feila ved sletting av denne innlogginga',
            error_response_unknown: 'Noko feila ved sletting av denne innlogginga',
        }
    })

</script>

<div class="session-list">
    {#if embeddedMode}
        <p class="title">{t('title')}</p>
    {:else}
        <h2 class="title">{t('title')}</h2>
    {/if}

    {#if notification}
        <MessageBox type="error">
            {notification}
        </MessageBox>
    {/if}

    <ul>
        {#each sessions as session}
            <li>
                <div class="device-info">
                    {#if session.icon_class !== 'app'}
                        <InlineSVG svg={icons[session.icon_class]} --svg-square-size="30px" />
                        {#if session.icon_desc}
                            <b>{session.icon_desc}</b>
                        {/if}
                        {#if session.os_desc}
                            <i>{session.os_desc}</i>
                        {/if}
                    {:else}
                        <i class="app"></i>
                    {/if}
                </div>

                <div class="session-info">
                    {#if session.this_session}
                        <p class="session-current">{t('current_session')}</p>
                    {/if}

                    <p class="session-last-used">
                        <span>{t('last_used')}&nbsp;</span>
                        <b>{session.last_used}</b>
                    </p>
                    {#if session.visited_publications.length > 0}
                        <p class="session-visited-publications">
                            {t('visited_publications')}
                            <span class="publications" data-all-publications="">
                                {#if session.showAll}
                                    {session.visited_publications.join(', ')}
                                {:else}
                                    {session.visited_publications.slice(0,5).join(', ')}
                                    {#if session.visited_publications.length > 5}
                                        &hellip;
                                        <LinkButton on:click={() => session.showAll = true}>{t('show_more_publications', {num: session.visited_publications.length - 5})}</LinkButton>
                                    {/if}
                                {/if}
                            </span>
                        </p>
                    {/if}
                    {#if !session.this_session}
                        <div class="session-remove">
                            <LinkButton logName="logout_session" data="logout-session" on:click={() => logoutSession(session)}>{t('logout_device')}</LinkButton>
                        </div>
                    {/if}
                </div>
            </li>
        {/each}
    </ul>

    {#if !embeddedMode}
        <div class="buttons-wrapper">
            {#if sessions.length > 0}
                <div class="delete-button-wrapper">
                    <Form action="/aid/innlogginger/slett_alle">
                        <Button logName="delete_all_session_from_sessions_page">{t('logout_all')}</Button>
                    </Form>
                </div>
            {/if}

            <div class="profile-button-wrapper">
                <Button prio="true" logName="profile_page_from_sessions_overview" href="/aid/profil">{t('back')}</Button>
            </div>
        </div>
    {/if}

    {#if maxSessions}
        <div class="list-msg-container">
            {#if sessions.length > maxSessions}
                <div class="list-too-long-msg">
                    <MessageBox type="error">
                        {t('too_long', {num: sessions.length - maxSessions})}
                    </MessageBox>
                </div>
            {:else}
                <MessageBox type="success" cta={t('continue')} on:cta={() => window.location.href=requestedUrl} >
                    {t('max_length_success')}
                </MessageBox>
            {/if}
        </div>
    {/if}

</div>

<style>
@import '../common/colors.css';

.session-list {
    display: block;
    font-size: 16px;
    margin: 20px 0;
    color: var(--black);
    width: 100%;
}

.session-list .title {
    margin-bottom: 1em;
}

.session-list > ul {
    list-style-type: none;
    margin: 10px 0;
}

.session-list > ul > li {
    display: flex;
    flex-direction: row;
    margin: 0;
    border-top: 3px solid var(--lightGray);
    padding: 1em 0;
}

.session-list > ul > li:last-child{
    border-bottom: 3px solid var(--lightGray);
}

.session-list .device-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    min-width: 90px;
    margin: 0 5px;
    color: var(--black);
    line-height: 18px;
}

.session-list .device-info > *:not(:first-child) {
    font-size: 13px;
    color: var(--darkGray);
}

.session-list .device-info b {
    text-align: center;
    font-size: 18px;
}

.session-list .device-info :global(svg) {
    display: block;
    margin: 0px auto 8px auto;
}

.session-list .session-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 75px;
    font-size: 16px;
    margin: 0 5px 0 10px;
    word-break: break-word;
}

.session-list .session-info .session-current{
    font-weight: bold;
}

.session-list .session-info .session-last-used {
    display: flex;
    flex-wrap: wrap;
    color: var(--darkGray);
}

.session-list .session-info .session-last-used b {
    color: var(--black);
}

.session-list .session-visited-publications {
    line-height: 24px;
    color: var(--darkGray);
}

.session-list .session-visited-publications .publications {
    font-weight: bold;
    color: var(--black);
}


.session-list .buttons-wrapper {
    display: flex;
    margin: 60px 0;
    gap: 40px;
}

.session-list .delete-button-wrapper, .profile-button-wrapper {
    width: 100%;
    text-align: center;
}

.session-list .device-info i.app {
    display: block;
    height: 30px;
    position: relative;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
}

.session-list .device-info i.app:before {
    position: absolute;
    bottom: 0;
    transform: translate(-50%);
    color: white;
    border-radius: 6px;
    content: 'APP';
    padding: 5px;
    background: var(--black);
}


</style>